import React, { useState } from "react";
import img from "../../../assets/images/anim_line.png";
import appleStoreBlue from "../../../assets/images/appstore_blue.png";
import screen from "../../../assets/images/download-screen01.png";
import screen1 from "../../../assets/images/download-screen02.png";
import blue from "../../../assets/images/googleplay_blue.png";
import huaweiStoreBlue from "../../../assets/images/huawei_blue.png";
const Main = () => {
	const [animate, setanimate] = useState();

	const [purple, setpurple] = useState();
	window.addEventListener("scroll", () => {
		const element = document.getElementsByClassName("free_text");
		const position = element[0]?.getBoundingClientRect();

		if (position.top < window.innerHeight && position.bottom >= 0) {
			const elm = document.getElementsByClassName("purple_backdrop");
			elm[0].style.opacity = "1";
		} else {
			const elm = document.getElementsByClassName("purple_backdrop");
			elm[0].style.opacity = "0";
		}
	});

	return (
		<>
			<section
				className="row_am free_app_section review_freeapp"
				id="getstarted"
			>
				<div className="container ">
					<div
						className={`free_app_inner aos-init ${animate && "aos-animate"}`}
						data-aos="fade-in"
						data-aos-duration="1500"
						data-aos-delay="100"
					>
						<div className="anim_line dark_bg">
							<span>
								<img src={img} alt="anim_line" />
							</span>
							<span>
								<img src={img} alt="anim_line" />
							</span>
							<span>
								<img src={img} alt="anim_line" />
							</span>
							<span>
								<img src={img} alt="anim_line" />
							</span>
							<span>
								<img src={img} alt="anim_line" />
							</span>
							<span>
								<img src={img} alt="anim_line" />
							</span>
							<span>
								<img src={img} alt="anim_line" />
							</span>
							<span>
								<img src={img} alt="anim_line" />
							</span>
							<span>
								<img src={img} alt="anim_line" />
							</span>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className="free_text">
									<div className="section_title">
										<h2>Let’s download free from apple and play store</h2>
										<p>
											Get Instant Access: Download Postgoo for Free on the App
											Store and Google Play Store Today!
										</p>
									</div>
									<ul className="app_btn">
										{/* <li>
                                            <Link to="#">
                                                <img src={blueapp}   />
                                            </Link>
                                        </li> */}
										<li>
											<a
												href="https://apps.apple.com/us/app/postgoo/id6523412991"
												target="_blank"
												rel="noreferrer"
											>
												<img className="" src={appleStoreBlue} />
											</a>
										</li>
										<li>

											<a
												href="https://play.google.com/store/apps/details?id=app.postgoo.com"
												target="_blank"
												rel="noreferrer"
											>
												<img src={blue} />
											</a>
										</li>

									</ul>
									<ul className="app_btn" >
										<li >
											<a
												href="https://appgallery.huawei.com/#/app/C111391163"
												target="_blank"
												rel="noreferrer"
												style={{ padding: "12px 25px" }}
											>
												<img className="blue_img" src={huaweiStoreBlue} />
											</a>
										</li>

									</ul>
								</div>
							</div>
							<div className="col-md-6">
								<div className="free_img">
									<img src={screen} />
									<img className="mobile_mockup" src={screen1} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="purple_backdrop"></div>
		</>
	);
};

export default Main;
