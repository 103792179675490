import React from 'react'

const Privacy = ({ brdcum }) => {

    return (
        <>
            <section
                style={{
                    marginTop: "50px"
                }}
                className="row_am review_list_section">
                <div className="container privacy-container">
                    <div className="row">
                        <div className="">
                            <h1>Privacy Policy for Postgoo</h1>
                            <p><strong>Effective Date:</strong> January 06, 2024</p>

                            <h2>Introduction</h2>
                            <p>
                                Postgoo operates the postgoo.com website and the Postgoo mobile application. This policy informs you about our
                                practices related to the collection, use, and disclosure of personal and usage data in our Service.
                            </p>

                            <h2>Acceptance of Terms</h2>
                            <p>
                                By accessing or using our Service, you signify your understanding of and agreement with this Privacy Policy.
                            </p>

                            <h2>Information Collection</h2>
                            <h3>Types of Data Collected</h3>
                            <ul>
                                <li><strong>Personal Data:</strong> This includes, but is not limited to, your email address, first name, last
                                    name, and other identifiers when you interact with our Service.</li>
                                <li><strong>Usage Data:</strong> Information on how the Service is accessed and used (e.g., time spent on pages,
                                    pages visited).</li>
                            </ul>

                            <h2>Use of Data</h2>
                            <p>Data collected is used to:</p>
                            <ul>
                                <li>Provide and maintain our Service</li>
                                <li>Notify you about changes to our Service</li>
                                <li>Allow participation in interactive features</li>
                                <li>Provide customer support and respond to inquiries</li>
                                <li>Monitor the usage of the Service for improvements</li>
                                <li>Detect, prevent, and address technical issues</li>
                                <li>Provide news, special offers, and general information about other goods and services.
                                </li>
                            </ul>

                            <h2>Transfer of Data</h2>
                            <p>
                                Your information, including Personal Data, may be transferred to computers located outside of your state or
                                country where data protection laws may differ.
                            </p>

                            <h2>Disclosure of Data</h2>
                            <p>
                                We may disclose your Personal Data for law enforcement, to comply with laws, to protect the rights or safety of
                                Postgoo or the public, and to prevent wrongdoing in connection with our Service.
                            </p>

                            <h2>Data Security</h2>
                            <p>
                                The security of your data is important to us. We strive to use commercially acceptable means to protect your
                                Personal Data but cannot guarantee its absolute security.
                            </p>

                            <h2>Data Protection Rights</h2>
                            <p>
                                You have rights to access, update, delete, or place restrictions on the use of your Personal Data. We will take
                                steps to allow you to correct, amend, or delete your information.
                            </p>

                            <h2>Service Providers</h2>
                            <p>
                                We may use third-party companies and individuals to facilitate our Service, provide the Service on our behalf,
                                perform Service-related tasks, or assist us in analyzing how our Service is used.
                            </p>

                            <h2>Third-Party Links</h2>
                            <p>
                                Our Service may contain links to external sites not operated by us. Clicking on a third-party link will direct
                                you to that site. We advise reviewing the Privacy Policy of each site you visit.
                            </p>

                            <h2>Children's Privacy</h2>
                            <p>
                                Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable
                                information from children under 18. If we become aware of this, we take steps to remove the information from our
                                servers.
                            </p>
                            <h2>Third-Party Services and Data Sharing</h2>
                            <p>
                                Integration of Third-Party Services: Our Service uses third-party tools to enhance functionality, analyze user
                                behavior, and improve overall user experience. These include:
                            </p>
                            <ul>
                                <li><strong>Sentry:</strong> For error tracking and monitoring to enhance app stability. <a
                                    href="https://sentry.io/privacy/">Sentry Privacy Policy</a></li>
                                <li><strong>Google Analytics:</strong> To analyze user interactions and engagement with our Service. <a
                                    href="https://policies.google.com/privacy">Google Privacy Policy</a></li>
                                <li><strong>Posthog:</strong> For in-depth analytics and user insights. <a
                                    href="https://posthog.com/privacy">Posthog Privacy Policy</a></li>
                            </ul>
                            <p>
                                By using our Service, you consent to the collection and use of information by these third-party tools as
                                described in their privacy policies. These services may collect information such as your device type, operating
                                system, unique device identifiers, IP address, and user interactions within our app.
                            </p>
                            <p>
                                We choose these third-party services carefully to ensure that your data is treated securely and in accordance
                                with this Privacy Policy. However, the data collected by these services is subject to their respective privacy
                                policies, which we recommend you review.
                            </p>

                            <h2>Data Types Collected</h2>
                            <p>We collect the following data types from our app:</p>
                            <ul>
                                <li><strong>Name:</strong> Used for Product Personalization and App Functionality. Linked to the user's
                                    identity.</li>
                                <li><strong>Email Address:</strong> Used for App Functionality. Linked to the user's identity.</li>
                                <li><strong>Photos or Videos:</strong> Used for Product Personalization. Linked to the user's identity.</li>
                                <li><strong>Customer Support:</strong> Used for Analytics, App Functionality, and Product Personalization.
                                    Linked to the user's identity.</li>
                                <li><strong>Other User Content:</strong> Used for Product Personalization, Analytics, and App Functionality.
                                    Linked to the user's identity.</li>
                                <li><strong>Search History:</strong> Used for Analytics, App Functionality, Other Purposes, and Product
                                    Personalization. Linked to the user's identity.</li>
                                <li><strong>User ID:</strong> Used for Product Personalization, Analytics, and App Functionality. Linked to the
                                    user's identity.</li>
                                <li><strong>Device ID:</strong> Used for Product Personalization, Other Purposes, App Functionality, and
                                    Analytics. Linked to the user's identity.</li>
                                <li><strong>Product Interaction:</strong> Used for Product Personalization, App Functionality, Other Purposes,
                                    and Analytics. Linked to the user's identity.</li>
                                <li><strong>Crash Data:</strong> Used for Analytics and App Functionality.</li>
                                <li><strong>Performance Data:</strong> Used for App Functionality and Analytics.</li>
                                <li><strong>Other Diagnostic Data:</strong> Used for Analytics and App Functionality.</li>
                            </ul>

                            <h2>Changes to This Privacy Policy</h2>
                            <p>
                                Our Privacy Policy may change from time to time. We will notify you of any changes and update the "effective
                                date" at the top of this Privacy Policy.
                            </p>

                            <h2>Contact Us</h2>
                            <p>
                                For questions about this Privacy Policy, contact us at contact@postgoo.com.
                            </p>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default Privacy