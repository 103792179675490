import React from "react";
import img5 from "../../../assets/images/features_frame.png";
import img2 from "../../../assets/images/functional.png";
import { default as img3, default as img4 } from "../../../assets/images/live-chat.png";
import img1 from "../../../assets/images/secure_data.png";

const Main = ({ video }) => {
    return (
        <>
            {video ? (
                <section
                    className="row_am features_section video-features"
                    id="features"
                >
                    <div className="container">
                        <div
                            className="section_title"
                            data-aos="fade-up"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            <h2>
                                <span>Features</span> that makes app different!
                            </h2>
                            <p>
                                Experience the Difference: Explore the Unique and Powerful
                                Features of <br /> Postgoo app
                            </p>
                        </div>
                        <div className="feature_detail">
                            <div className="left_data feature_box">
                                <div
                                    className="data_block"
                                    data-aos="fade-right"
                                    data-aos-duration="1500"
                                >
                                    <div className="icon">
                                        <img src="assets/images/secure.png"   />
                                    </div>
                                    <div className="text">
                                        <h4>Real-time Updates</h4>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and type
                                            setting indus ideas.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="data_block"
                                    data-aos="fade-right"
                                    data-aos-duration="1500"
                                >
                                    <div className="icon">
                                        <img src="assets/images/abt_functional.png"   />
                                    </div>
                                    <div className="text">
                                        <h4>Fully functional</h4>
                                        <p>
                                            Simply dummy text of the printing and typesetting indus
                                            lorem Ipsum is dummy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="right_data feature_box">
                                <div
                                    className="data_block"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    <div className="icon">
                                        <img src="assets/images/communication.png"   />
                                    </div>
                                    <div className="text">
                                        <h4>Live chat</h4>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and type
                                            setting indus ideas.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="data_block"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    <div className="icon">
                                        <img src="assets/images/abt_support.png"   />
                                    </div>
                                    <div className="text">
                                        <h4>24-7 ss</h4>
                                        <p>
                                            Simply dummy text of the printing and typesetting indus
                                            lorem Ipsum is dummy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="feature_img"
                                data-aos="fade-up"
                                data-aos-duration="1500"
                                data-aos-delay="100"
                            >
                                <img src="assets/images/features_frame.png"   />
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="row_am features_section" id="features">
                    <div className="container">
                        <div
                            className="section_title"
                            data-aos="fade-up"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            <h2>
                                <span>Features</span> that makes app different!
                            </h2>
                            <p>
                                Experience the Difference: Explore the Unique and Powerful
                                Features of <br /> Postgoo app
                            </p>
                        </div>
                        <div className="feature_detail">
                            <div className="left_data feature_box">
                                <div
                                    className="data_block"
                                    data-aos="fade-right"
                                    data-aos-duration="1500"
                                >
                                    <div className="icon">
                                        <img src={img1}   />
                                    </div>
                                    <div className="text">
                                        <h4>Real-time Updates</h4>
                                        <p>
                                            Stay up-to-date with the latest breaking news and stories
                                            as they happen with our app's real-time updates.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="data_block"
                                    data-aos="fade-right"
                                    data-aos-duration="1500"
                                >
                                    <div className="icon">
                                        <img src={img2}   />
                                    </div>
                                    <div className="text">
                                        <h4>Advanced Search Engine</h4>
                                        <p>
                                            Our app's advanced search engine allows you to quickly and
                                            easily find the news articles that matter to you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="right_data feature_box">
                                <div
                                    className="data_block"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    <div className="icon">
                                        <img src={img3}   />
                                    </div>
                                    <div className="text">
                                        <h4>Keyword Monitoring</h4>
                                        <p>
                                            Monitor keywords across news sources globally. Analyze sentiment, visualize trends with powerful analytics tools and receive customized reports.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="data_block"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    <div className="icon">
                                        <img src={img4}   />
                                    </div>
                                    <div className="text">
                                        <h4>Personalized news alerts</h4>
                                        <p>
                                            Take control of your news updates with our app's push
                                            notification feature.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="feature_img"
                                data-aos="fade-up"
                                data-aos-duration="1500"
                                data-aos-delay="100"
                            >
                                <img src={img5}   />
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default Main;
