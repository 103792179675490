import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import frame from "../../../assets/images/about-frame.png";
import screen from "../../../assets/images/about-screen.png";
import img2 from "../../../assets/images/articles.png";
import img4 from "../../../assets/images/countries.png";
import podcasts from "../../../assets/images/podcasts.png";

import publishers from "../../../assets/images/publishers.png";

const Main = ({ video, dark }) => {
	const location = useLocation();
	const history = useNavigate();
	const path = location.pathname;

	useEffect(() => {
		window.scroll(0, 0);
		console.log(path);
	}, [path]);

	const handleScrollToDownload = () => {
		const element = document.getElementById("getstarted");
		if (element) {
			const yOffset = -window.innerHeight / 2 + element.clientHeight / 2; // Offset to center the element
			const yPosition =
				element?.getBoundingClientRect().top + window.pageYOffset + yOffset;
			window.scrollTo({ top: yPosition, behavior: "smooth" });
		}
	};
	return (
		<>
			<section className="row_am about_app_section">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div
								className="about_img"
								data-aos="fade-in"
								data-aos-duration="1500"
							>
								<div className="frame_img">
									<img className="moving_position_animatin" src={frame} />
								</div>
								<div className="screen_img">
									<img className="moving_animation" src={screen} />
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about_text">
								<div
									className="section_title"
									data-aos="fade-up"
									data-aos-duration="1500"
									data-aos-delay="100"
								>
									<h2>
										Some awesome stats <span>about app.</span>
									</h2>
									<p>
										Stay ahead of the curve with our top-rated news aggregator
										app: Featuring real-time updates, breaking news alerts, and
										exclusive content, we're the ultimate source for news and
										information for users worldwide.
									</p>
								</div>
								{dark ? (
									<ul className="list">
										<li data-aos="fade-up" data-aos-duration="1500">
											<i className="icofont-verification-check"></i> Simply
											dummy text of the printing and
										</li>
										<li data-aos="fade-up" data-aos-duration="1500">
											<i className="icofont-verification-check"></i> Typesetting
											industry lorem Ipsum has been the
										</li>
										<li data-aos="fade-up" data-aos-duration="1500">
											<i className="icofont-verification-check"></i> Industrys
											standard dummy text
										</li>
										<li data-aos="fade-up" data-aos-duration="1500">
											<i className="icofont-verification-check"></i> Simply
											dummy text of the printing and
										</li>
										<li data-aos="fade-up" data-aos-duration="1500">
											<i className="icofont-verification-check"></i> Typesetting
											industry lorem Ipsum has been the
										</li>
									</ul>
								) : (
									<ul
										className="app_statstic"
										id="counter"
										data-aos="fade-in"
										data-aos-duration="1500"
									>
										<li>
											<div className="icon">
												{video ? (
													<img src="assets/images/download-one.png" />
												) : (
													<img src={publishers} />
												)}
											</div>
											<div className="text">
												<p>
													<span className="counter-value" data-count="300">
														0
													</span>
													<span>K+</span>
												</p>
												<p>Publishers</p>
											</div>
										</li>
										<li>
											<div className="icon">
												{video ? (
													<img src="assets/images/followers-one.png" />
												) : (
													<img src={img2} />
												)}
											</div>
											<div className="text">
												<p>
													<span className="counter-value" data-count="300">
														0{" "}
													</span>
													<span>M+</span>
												</p>
												<p>Articles</p>
											</div>
										</li>
										<li>
											<div className="icon">
												{video ? (
													<img src="assets/images/reviews-one.png" />
												) : (
													<img src={podcasts} />
												)}
											</div>
											<div className="text">
												<p>
													<span className="counter-value" data-count="230">
														0
													</span>
													<span>K+</span>
												</p>
												<p>Podcasts</p>
											</div>
										</li>
										<li>
											<div className="icon">
												{video ? (
													<img src="assets/images/countries-one.png" />
												) : (
													<img src={img4} />
												)}
											</div>
											<div className="text">
												<p>
													<span className="counter-value" data-count="20">
														0
													</span>
													<span>+</span>
												</p>
												<p>Countries</p>
											</div>
										</li>
									</ul>
								)}
								{/* <button
                                    smooth
                                    onClick={handleScrollToDownload}
                                    className="btn puprple_btn"
                                    data-aos="fade-in"
                                    data-aos-duration="1500"
                                >
                                    DOWNLOAD NOW
                                </button> */}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Main;
