import React from "react";
import img1 from "../../../assets/images/modern01.png";
import img3 from "../../../assets/images/modern02.png";
import img4 from "../../../assets/images/modern03.png";
import img2 from "../../../assets/images/secure_data.png";

const Main = ({ gredient }) => {
    return (
        <>
            <section
                className={`row_am modern_ui_section ${gredient && "gredient-bg"}`}
            >
                {gredient && (
                    <div className="modernui_section_bg modernui-gredient">
                        {" "}
                        <img src="assets/images/section-bg.png"   />{" "}
                    </div>
                )}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="ui_text">
                                <div
                                    className="section_title"
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-delay="100"
                                >
                                    <h2>
                                        Beautiful design with <span>modern UI</span>
                                    </h2>
                                    <p>
                                        Our news aggregator app not only offers the latest news and
                                        updates, but it also boasts a beautiful, modern user
                                        interface that is both intuitive and easy to use. With its
                                        clean design and user-friendly features, our app makes it
                                        easy to stay informed and up-to-date with the latest news
                                        and trends from around the world.
                                    </p>
                                </div>
                                <ul className="design_block">
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>A seamless user experience</h4>
                                        <p>
                                            Our app's modern UI is designed to provide a seamless user
                                            experience, with intuitive navigation and easy-to-use
                                            features that make it easy to find the news and
                                            information you need.
                                        </p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Stay up-to-date with ease</h4>
                                        <p>
                                            With our app, you can access your news feed across
                                            multiple devices, and your preferences and settings will
                                            be synced seamlessly.
                                        </p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Effortlessly switch between modes</h4>
                                        <p>
                                            Our app's dark light mode feature makes it easy to switch
                                            between light and dark modes with just a tap. Whether
                                            you're in a bright environment or a dark room, our app
                                            adapts to your needs and preferences.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="ui_images"
                                data-aos="fade-in"
                                data-aos-duration="1500"
                            >
                                <div className="left_img">
                                    <img
                                        className="moving_position_animatin"
                                        src={img1}
                                         
                                    />
                                </div>
                                <div className="right_img">
                                    <img
                                        className="moving_position_animatin"
                                        src={img2}
                                         
                                    />
                                    <img
                                        className="moving_position_animatin"
                                        src={img3}
                                         
                                    />
                                    <img
                                        className="moving_position_animatin"
                                        src={img4}
                                         
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Main;
