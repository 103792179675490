import React from "react";
import { Link } from "react-router-dom";
import BGImg from "../../assets/images/bread_crumb_bg.png";
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png";
import Bredcrumb from "../Bredcrumb/Main";

const Main = ({ brdcum }) => {
    return (
        <>
            {brdcum.b1 && (
                <Bredcrumb
                    no={1}
                    title="Contact Us"
                    paragraph="Ib you have an query, please get in touch with us, we will revert back quickly."
                    tag="Contact us"
                    bgimg={BGImg}
                />
            )}

            {/* {brdcum.b2 && (
                <Bredcrumb
                    no={2}
                    title="Contact Us"
                    paragraph="If you have an query, please get in touch with us, we will revert back quickly."
                    tag="Contact us"
                    bgimg={BGImg1}
                />
            )} */}

            {brdcum.b5 && (
                <Bredcrumb
                    no={5}
                    title="Contact Us"
                    paragraph="If you have an query, please get in touch with us, we will revert back quickly."
                    tag="Contact us"
                    bgimg={BGImg}
                />
            )}

            {brdcum.b3 && (
                <Bredcrumb
                    no={3}
                    title="Contact Us"
                    paragraph="If you have an query, please get in touch with us, we will revert back quickly."
                    tag="Contact us"
                />
            )}

            {brdcum.b4 && (
                <Bredcrumb
                    no={4}
                    title="Contact Us"
                    paragraph="If you have an query, please get in touch with us, we will revert back quickly."
                    tag="Contact us"
                    bgimg={BGImg2}
                />
            )}


            {/* {/*  30px empty space */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <section className="contact_page_section" id="contact">
                <div className="container">
                    <div className="contact_inner ">

                        <div className="contact_info">
                            <div className="icon">
                                <img src="assets/images/contact_message_icon.png"   />
                            </div>
                            <div className="section_title">
                                <h2>
                                    Have any <span>question?</span>
                                </h2>
                                <p>
                                    If you have any question about our product, service, payment
                                    or company.
                                </p>
                            </div>
                            {/* <Link to="/faq" className="btn puprple_btn">
                                READ FAQ
                            </Link> */}
                            <ul className="contact_info_list">
                                <li>
                                    <div className="img">
                                        <img src="assets/images/mail_icon.png"   />
                                    </div>
                                    <div className="text">
                                        <span>Email Us</span>
                                        <Link to="#">contact@postgoo.com</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="img">
                                        <img src="assets/images/call_icon.png"   />
                                    </div>
                                    <div className="text">
                                        <span>Call Us</span>
                                        <Link to="#">+212 (06)60-333340</Link>
                                    </div>
                                </li>
                                {/* <li>
                                    <div className="img">
                                        <img src="assets/images/location_icon.png"   />
                                    </div>
                                    <div className="text">
                                        <span>Visit Us</span>
                                        <p>
                                            étage , 48, Boulvard Med V , Résidence Rédouane 6 émé,
                                            Tanger
                                        </p>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="row_am map_section">
                {/* <div className="container">
                    <div className="map_inner">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12947.929010140668!2d-5.8020682!3d35.7758123!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd0c7f580f9a9fa3%3A0x509d073b3a7a908a!2sPostGoo!5e0!3m2!1sen!2sma!4v1681260275676!5m2!1sen!2sma"
                            width="100%"
                            height="510"
                            style={{ border: "0" }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div> */}
            </section>
        </>
    );
};

export default Main;
