import React, { useState } from "react";

const Main = ({ gredient }) => {
    const [activeFaq, setActiveFaq] = useState({ a: true });

    return (
        <>
            <section className="row_am faq_section">
                {gredient && (
                    <div className="faq_bg">
                        {" "}
                        <img src="assets/images/section-bg.png"   />{" "}
                    </div>
                )}
                <div className="container">
                    <div
                        className="section_title"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        data-aos-delay="300"
                    >
                        <h2>
                            <span>FAQ</span> - Frequently Asked Questions
                        </h2>
                        <p>
                            Help Center: Frequently Asked Questions About <br />
                            Postgoo App
                        </p>
                    </div>
                    <div className="faq_panel">
                        <div className="accordion" id="accordionExample">
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                        <button
                                            type="button"
                                            className={`btn btn-link ${activeFaq.a && "active"}`}
                                            onClick={() =>
                                                setActiveFaq(activeFaq.a ? { a: false } : { a: true })
                                            }
                                            data-toggle="collapse"
                                            data-target="#collapseOne"
                                        >
                                            {activeFaq.a ? (
                                                <i className="icon_faq icofont-minus"></i>
                                            ) : (
                                                <i className="icon_faq icofont-plus"></i>
                                            )}{" "}
                                            What is Postgoo and how does it work?
                                        </button>
                                    </h2>
                                </div>
                                <div
                                    id="collapseOne"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordionExample"
                                >
                                    <div className="card-body">
                                        <p>
                                            Postgoo is a mobile application that compiles news
                                            articles and podcasts from various sources around the
                                            world. Our app collects content from reputable news
                                            sources and categorizes it by topic, making it easy for
                                            you to stay informed on the subjects that matter most to
                                            you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                        <button
                                            type="button"
                                            className={`btn btn-link ${activeFaq.b && "active"}`}
                                            onClick={() =>
                                                setActiveFaq(activeFaq.b ? { b: false } : { b: true })
                                            }
                                            data-toggle="collapse"
                                            data-target="#collapseTwo"
                                        >
                                            {activeFaq.b ? (
                                                <i className="icon_faq icofont-minus"></i>
                                            ) : (
                                                <i className="icon_faq icofont-plus"></i>
                                            )}{" "}
                                            How do I create an account?
                                        </button>
                                    </h2>
                                </div>
                                <div
                                    id="collapseTwo"
                                    className="collapse"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordionExample"
                                >
                                    <div className="card-body">
                                        <p>
                                            To create an account, download the postgoo from the App
                                            Store or Google Play Store. After installation, launch the
                                            app and tap the "Sign Up" button. You will be prompted to
                                            provide your email address, create a password, and choose
                                            a username. Once you've completed the registration
                                            process, you can access your personalized news feed and
                                            adjust your preferences.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingThree">
                                    <h2 className="mb-0">
                                        <button
                                            type="button"
                                            className={`btn btn-link ${activeFaq.c && "active"}`}
                                            onClick={() =>
                                                setActiveFaq(activeFaq.c ? { c: false } : { c: true })
                                            }
                                            data-toggle="collapse"
                                            data-target="#collapseThree"
                                        >
                                            {activeFaq.c ? (
                                                <i className="icon_faq icofont-minus"></i>
                                            ) : (
                                                <i className="icon_faq icofont-plus"></i>
                                            )}
                                            Is Postgoo free to use?
                                        </button>
                                    </h2>
                                </div>
                                <div
                                    id="collapseThree"
                                    className="collapse"
                                    aria-labelledby="headingThree"
                                    data-parent="#accordionExample"
                                >
                                    <div className="card-body">
                                        <p>
                                            Yes, Postgoo is free to use. We offer a basic plan that
                                            provides access to all our core features at no cost. In
                                            the future, we may introduce premium features or
                                            subscriptions for additional functionality.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingFour">
                                    <h2 className="mb-0">
                                        <button
                                            type="button"
                                            className={`btn btn-link ${activeFaq.d && "active"}`}
                                            onClick={() =>
                                                setActiveFaq(activeFaq.d ? { d: false } : { d: true })
                                            }
                                            data-toggle="collapse"
                                            data-target="#collapseFour"
                                        >
                                            {activeFaq.d ? (
                                                <i className="icon_faq icofont-minus"></i>
                                            ) : (
                                                <i className="icon_faq icofont-plus"></i>
                                            )}
                                            How do I customize my news feed?
                                        </button>
                                    </h2>
                                </div>
                                <div
                                    id="collapseFour"
                                    className="collapse"
                                    aria-labelledby="headingFour"
                                    data-parent="#accordionExample"
                                >
                                    <div className="card-body">
                                        <p>
                                            After signing in to your account, navigate to the
                                            "Preferences" or "Settings" tab within the app. Here, you
                                            can select your favorite news categories, sources, and
                                            topics. Your personalized news feed will then display
                                            content tailored to your preferences.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingFive">
                                    <h2 className="mb-0">
                                        <button
                                            type="button"
                                            className={`btn btn-link ${activeFaq.e && "active"}`}
                                            onClick={() =>
                                                setActiveFaq(activeFaq.e ? { e: false } : { e: true })
                                            }
                                            data-toggle="collapse"
                                            data-target="#collapseFive"
                                        >
                                            {activeFaq.e ? (
                                                <i className="icon_faq icofont-minus"></i>
                                            ) : (
                                                <i className="icon_faq icofont-plus"></i>
                                            )}
                                            How do I report an issue or provide feedback?
                                        </button>
                                    </h2>
                                </div>
                                <div
                                    id="collapseFive"
                                    className="collapse"
                                    aria-labelledby="headingFive"
                                    data-parent="#accordionExample"
                                >
                                    <div className="card-body">
                                        <p>
                                            If you encounter any issues or have suggestions for
                                            improving our app, please contact our support team through
                                            the "Contact Us" form within the app or by emailing
                                            contact@postgoo.com. We appreciate your feedback and are
                                            dedicated to providing the best user experience possible.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingSix">
                                    <h2 className="mb-0">
                                        <button
                                            type="button"
                                            className={`btn btn-link ${activeFaq.f && "active"}`}
                                            onClick={() =>
                                                setActiveFaq(activeFaq.f ? { f: false } : { f: true })
                                            }
                                            data-toggle="collapse"
                                            data-target="#collapseSix"
                                        >
                                            {activeFaq.f ? (
                                                <i className="icon_faq icofont-minus"></i>
                                            ) : (
                                                <i className="icon_faq icofont-plus"></i>
                                            )}
                                            Is my personal information secure?
                                        </button>
                                    </h2>
                                </div>
                                <div
                                    id="collapseSix"
                                    className="collapse"
                                    aria-labelledby="headingSix"
                                    data-parent="#accordionExample"
                                >
                                    <div className="card-body">
                                        <p>
                                            We take user privacy and security very seriously. Postgoo
                                            follows industry best practices to protect your personal
                                            information. For more details, please refer to our Privacy
                                            Policy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingSeven">
                                    <h2 className="mb-0">
                                        <button
                                            type="button"
                                            className={`btn btn-link ${activeFaq.g && "active"}`}
                                            onClick={() =>
                                                setActiveFaq(activeFaq.g ? { g: false } : { g: true })
                                            }
                                            data-toggle="collapse"
                                            data-target="#collapseSeven"
                                        >
                                            {activeFaq.g ? (
                                                <i className="icon_faq icofont-minus"></i>
                                            ) : (
                                                <i className="icon_faq icofont-plus"></i>
                                            )}
                                            How often is news content updated?
                                        </button>
                                    </h2>
                                </div>
                                <div
                                    id="collapseSeven"
                                    className="collapse"
                                    aria-labelledby="headingSeven"
                                    data-parent="#accordionExample"
                                >
                                    <div className="card-body">
                                        <p>
                                            Our app continuously updates with the latest news articles
                                            and podcasts from our partnered sources. This ensures that
                                            you always have access to the most recent and relevant
                                            information.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Main;
