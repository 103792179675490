import React from 'react'

const Terms = ({ brdcum }) => {

    return (
        <>
            <section
                style={{
                    marginTop: "50px"
                }}
                className="row_am review_list_section">
                <div className="container privacy-container">
                    <div className="row">
                        <div className="">
                            <h1>Terms of Service for Postgoo</h1>
                            <p><strong>Effective Date:</strong> January 06, 2024</p>

                            <h2>Introduction</h2>
                            <p>
                                Welcome to Postgoo! By accessing or using our Service, you agree to be bound by these Terms of Service ("Terms"). If
                                you do not agree to these Terms, please do not use our Service.
                            </p>

                            <h2>Definitions</h2>
                            <ul>
                                <li><strong>Service:</strong> The Postgoo website and mobile application operated by Postgoo.</li>
                                <li><strong>User:</strong> Any individual or entity using our Service.</li>
                                <li><strong>Content:</strong> Any text, images, videos, links, or other materials posted on or through the Service.
                                </li>
                            </ul>

                            <h2>Eligibility</h2>
                            <p>
                                You must be at least 18 years old to use our Service. By using the Service, you represent and warrant that you meet
                                this age requirement.
                            </p>

                            <h2>User Accounts</h2>
                            <p>
                                To access certain features of our Service, you may need to create an account. You agree to provide accurate,
                                current, and complete information during the registration process and to update such information to keep it
                                accurate, current, and complete.
                            </p>
                            <p>
                                You are responsible for safeguarding your account credentials and for any activities or actions under your account.
                                You agree to notify us immediately of any unauthorized use of your account.
                            </p>

                            <h2>Privacy</h2>
                            <p>
                                Our Privacy Policy explains how we collect, use, and share your personal information. By using our Service, you
                                consent to our collection and use of your data as outlined in the Privacy Policy.
                            </p>

                            <h2>Use of the Service</h2>
                            <p>
                                You agree to use the Service only for lawful purposes and in accordance with these Terms. You agree not to:
                            </p>
                            <ul>
                                <li>Use the Service in any way that violates any applicable federal, state, local, or international law or
                                    regulation.</li>
                                <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the Service.</li>
                                <li>Use the Service for any unauthorized or illegal purposes.</li>
                                <li>Upload or transmit any material that contains viruses or any other harmful programs.</li>
                                <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Service, the server
                                    on which the Service is stored, or any server, computer, or database connected to the Service.</li>
                            </ul>

                            <h2>Content</h2>
                            <p>
                                You are responsible for the content you post on or through the Service. By posting content, you grant us a
                                non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, distribute, modify, run, copy,
                                publicly perform, display, translate, and create derivative works of your content.
                            </p>
                            <p>
                                We do not endorse, support, represent, or guarantee the completeness, truthfulness, accuracy, or reliability of any
                                content posted on or through the Service. You understand that by using the Service, you may be exposed to content
                                that might be offensive, harmful, inaccurate, or otherwise inappropriate.
                            </p>

                            <h2>Intellectual Property</h2>
                            <p>
                                The Service and its original content, features, and functionality are and will remain the exclusive property of
                                Postgoo and its licensors. The Service is protected by copyright, trademark, and other laws of both the United
                                States and foreign countries.
                            </p>
                            <p>
                                You agree not to use, copy, modify, distribute, or create derivative works based on any part of the Service or the
                                content available on the Service, except as expressly authorized in these Terms.
                            </p>

                            <h2>Third-Party Links</h2>
                            <p>
                                Our Service may contain links to third-party websites or services that are not owned or controlled by Postgoo. We
                                have no control over, and assume no responsibility for, the content, privacy policies, or practices of any
                                third-party websites or services. You acknowledge and agree that Postgoo shall not be responsible or liable,
                                directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or
                                reliance on any such content, goods, or services available on or through any such websites or services.
                            </p>

                            <h2>Termination</h2>
                            <p>
                                We may terminate or suspend your account and bar access to the Service immediately, without prior notice or
                                liability, for any reason whatsoever, including without limitation if you breach the Terms.
                            </p>
                            <p>
                                Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you
                                may simply discontinue using the Service.
                            </p>

                            <h2>Limitation of Liability</h2>
                            <p>
                                To the fullest extent permitted by applicable law, in no event shall Postgoo, its affiliates, directors, employees,
                                or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including without
                                limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or
                                inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information
                                stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses,
                                trojan horses, or the like that may be transmitted to or through our Service by any third party; (v) any errors or
                                omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed,
                                transmitted, or otherwise made available through the Service; and/or (vi) the defamatory, offensive, or illegal
                                conduct of any third party. In no event shall Postgoo's liability to you for all damages, losses, and causes of
                                action exceed the amount you have paid Postgoo in the last six (6) months.
                            </p>

                            <h2>Disclaimer</h2>
                            <p>
                                Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The
                                Service is provided without warranties of any kind, whether express or implied, including, but not limited to,
                                implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.
                            </p>
                            <p>
                                Postgoo, its subsidiaries, affiliates, and its licensors do not warrant that (i) the Service will function
                                uninterrupted, secure, or available at any particular time or location; (ii) any errors or defects will be
                                corrected; (iii) the Service is free of viruses or other harmful components; or (iv) the results of using the
                                Service will meet your requirements.
                            </p>

                            <h2>Governing Law</h2>
                            <p>
                                These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its
                                conflict of law provisions.
                            </p>
                            <p>
                                Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any
                                provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms
                                will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede
                                and replace any prior agreements we might have had between us regarding the Service.
                            </p>

                            <h2>Changes</h2>
                            <p>
                                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
                                material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material
                                change will be determined at our sole discretion.
                            </p>
                            <p>
                                By continuing to access or use our Service after those revisions become effective, you agree to be bound by the
                                revised terms. If you do not agree to the new terms, please stop using the Service.
                            </p>

                            <h2>Contact Us</h2>
                            <p>
                                If you have any questions about these Terms, please contact us at info@postgoo.com.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Terms