import React from "react";
import { Link } from "react-router-dom";
import line from "../../assets/images/anim_line.png";
import appleStoreBlue from "../../assets/images/appstore_blue.png";
import logo from "../../assets/images/footer_logo.png";
import top from "../../assets/images/go_top.png";
import blue from "../../assets/images/googleplay_blue.png";
import huaweiStoreBlue from "../../assets/images/huawei_blue.png";
const Main = ({ footer }) => {
	return (
		<>
			{/* <section className="newsletter_section">
                <div className="container">
                    <div className="newsletter_box">
                        <div
                            className="section_title"
                            data-aos="fade-in"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            <h2>Subscribe newsletter</h2>
                            <p>Be the first to recieve all latest post in your inbox</p>
                        </div>
                        <form
                            action=""
                            data-aos="fade-in"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                />
                            </div>
                            <div className="form-group">
                                <button className="btn">SUBMIT</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section> */}

			{footer.f1 && (
				<footer>
					<div className="top_footer" id="contact">
						<div className="anim_line dark_bg">
							<span>
								<img src={line} alt="anim_line" />
							</span>
							<span>
								<img src={line} alt="anim_line" />
							</span>
							<span>
								<img src={line} alt="anim_line" />
							</span>
							<span>
								<img src={line} alt="anim_line" />
							</span>
							<span>
								<img src={line} alt="anim_line" />
							</span>
							<span>
								<img src={line} alt="anim_line" />
							</span>
							<span>
								<img src={line} alt="anim_line" />
							</span>
							<span>
								<img src={line} alt="anim_line" />
							</span>
							<span>
								<img src={line} alt="anim_line" />
							</span>
						</div>
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-6 col-12">
									<div className="abt_side">
										<div className="logo">
											{" "}
											<img src={logo} />
										</div>
										<ul>
											<li>
												<Link to="#">contact@postgoo.com</Link>
											</li>
											<li>
												<Link to="#">+212 (06)60-333340</Link>
											</li>
										</ul>
										<ul className="social_media">
											<li>
												<a href="https://facebook.com/postgoo" target="blank">
													<i className="icofont-facebook"></i>
												</a>
											</li>
											<li>
												<a href="https://twitter.com/postgoo" target="blank">
													<i className="icofont-twitter"></i>
												</a>
											</li>
											<li>
												<a href="https://instagram.com/postgoo" target="blank">
													<i className="icofont-instagram"></i>
												</a>
											</li>
											<li>
												<a href="https://pinterest.com/postgoo" target="blank">
													<i className="icofont-pinterest"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-12">
									<div className="links">
										<h3>Useful Links</h3>
										<ul>
											<li>
												<Link to="/">Home</Link>
											</li>

											<li>
												<a
													href="https://blog.postgoo.com"
													target="_blank"
													rel="noreferrer"
												>
													{" "}
													Blog
												</a>
											</li>
											<li>
												<Link to="/contact">Contact us</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-12">
									<div className="links">
										<h3>Help & Suport</h3>
										<ul>
											<li>
												<Link to="/#how_it_work">How it works</Link>
											</li>
											<li>
												<Link to="/tos">Terms & conditions</Link>
											</li>
											<li>
												<Link to="/privacy">Privacy policy</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-2 col-md-6 col-12">
									<div className="try_out">
										<h3>Let’s Try Out</h3>
										<ul className="app_btn">
											{/* <li>
                                                <Link to="#">
                                                    <img src={blueapp}   />
                                                </Link>
                                            </li> */}
											<li>
												<a
													href="https://play.google.com/store/apps/details?id=app.postgoo.com"
													target="blank"
												>
													<img src={blue} />
												</a>
											</li>
											<li>
												<a
													href="https://apps.apple.com/us/app/postgoo/id6523412991"
													target="_blank"
													rel="noreferrer"
												>
													<img className="" src={appleStoreBlue} />
												</a>
											</li>
											<li>
												<a
													href="https://appgallery.huawei.com/#/app/C111391163"
													target="_blank"
													rel="noreferrer"
												>
													<img className="" src={huaweiStoreBlue} />
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="bottom_footer">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<p>© Copyrights 2024. All rights reserved.</p>
								</div>
								<div className="col-md-6">
									<p className="developer_text">
										Design & developed by{" "}
										<a href="https://postgoo.com" target="blank">
											Postgoo
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="go_top">
						<span>
							<img src={top} />
						</span>
					</div>
				</footer>
			)}

			{footer.f2 && (
				<footer>
					<div className="top_footer top_footer-dark" id="contact">
						<div className="container">
							<span className="banner_shape1">
								{" "}
								<img src="assets/images/banner-shape-one1.png" />{" "}
							</span>
							<span className="banner_shape2">
								{" "}
								<img src="assets/images/banner-shape2two.png" />{" "}
							</span>
							<span className="banner_shape3">
								{" "}
								<img src="assets/images/banner-shapethree3.png" />{" "}
							</span>
							<div className="row">
								<div className="col-lg-4 col-md-6 col-12">
									<div className="abt_side">
										<div className="logo">
											{" "}
											<img src="assets/images/footer_logo.png" />
										</div>
										<ul>
											<li>
												<Link to="#">contact@postgoo.com</Link>
											</li>
											<li>
												<Link to="#">+212 (06)60-333340</Link>
											</li>
										</ul>
										<ul className="social_media">
											<li>
												<Link to="#">
													<i className="icofont-facebook"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-twitter"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-instagram"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-pinterest"></i>
												</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-12">
									<div className="links">
										<h3>Useful Links</h3>
										<ul>
											<li>
												<Link to="/">Home</Link>
											</li>
											<li>
												<Link to="/about">About us</Link>
											</li>
											<li>
												<Link to="#">Services</Link>
											</li>
											<li>
												<Link to="/blog">Blog</Link>
											</li>
											<li>
												<Link to="/contact">Contact us</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-12">
									<div className="links">
										<h3>Help & Suport</h3>
										<ul>
											<li>
												<Link to="#">Support</Link>
											</li>
											<li>
												<Link to="/work">How it works</Link>
											</li>
											<li>
												<Link to="/tos">Terms & conditions</Link>
											</li>
											<li>
												<Link to="/privacy">Privacy policy</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-2 col-md-6 col-12">
									<div className="try_out">
										<h3>Let’s Try Out</h3>
										<ul className="app_btn">
											<li>
												<Link to="#">
													<img src="assets/images/appstore_blue.png" />
												</Link>
											</li>
											<li>
												<Link to="#">
													<img src="assets/images/googleplay_blue.png" />
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="bottom_footer bottom_footer-dark">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<p>© Copyrights 2024. All rights reserved.</p>
								</div>
								<div className="col-md-6">
									<p className="developer_text">
										Design & developed by{" "}
										<a href="https://postgoo.com" target="blank">
											Postgoo
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="go_top">
						<span>
							<img src="assets/images/go_top.png" />
						</span>
					</div>
				</footer>
			)}

			{footer.f3 && (
				<footer className="gredient-footer">
					<div className="footer_bg">
						{" "}
						<img src="assets/images/section-bg.png" />{" "}
					</div>
					<div className="top_footer" id="contact">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-6 col-12">
									<div className="abt_side">
										<div className="logo">
											{" "}
											<img src="assets/images/footer_logo-one.png" />
										</div>
										<ul>
											<li>
												<Link to="#">contact@postgoo.com</Link>
											</li>
											<li>
												<Link to="#">+212 (06)60-333340</Link>
											</li>
										</ul>
										<ul className="social_media">
											<li>
												<Link to="#">
													<i className="icofont-facebook"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-twitter"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-instagram"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-pinterest"></i>
												</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-12">
									<div className="links">
										<h3>Useful Links</h3>
										<ul>
											<li>
												<Link to="/">Home</Link>
											</li>
											<li>
												<Link to="/about">About us</Link>
											</li>
											<li>
												<Link to="#">Services</Link>
											</li>
											<li>
												<Link to="/blog">Blog</Link>
											</li>
											<li>
												<Link to="/contact">Contact us</Link>
											</li>
										</ul>
									</div>
								</div>

								<div className="col-lg-3 col-md-6 col-12">
									<div className="links">
										<h3>Help & Suport</h3>
										<ul>
											<li>
												<Link to="#">Support</Link>
											</li>
											<li>
												<Link to="/work">How it works</Link>
											</li>
											<li>
												<Link to="/tos">Terms & conditions</Link>
											</li>
											<li>
												<Link to="/privacy">Privacy policy</Link>
											</li>
										</ul>
									</div>
								</div>

								<div className="col-lg-2 col-md-6 col-12">
									<div className="try_out">
										<h3>Let’s Try Out</h3>
										<ul className="app_btn">
											<li>
												<Link to="#">
													<img src="assets/images/appstore_blue.png" />
												</Link>
											</li>
											<li>
												<Link to="#">
													<img src="assets/images/googleplay_blue.png" />
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="bottom_footer">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<p>© Copyrights 2024. All rights reserved.</p>
								</div>
								<div className="col-md-6">
									<p className="developer_text">
										Design & developed by{" "}
										<a href="https://postgoo.com" target="blank">
											Postgoo
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="go_top">
						<span>
							<img src="assets/images/go_top-one.png" />
						</span>
					</div>
				</footer>
			)}

			{footer.f4 && (
				<footer className="video-footer-two">
					<div className="top_footer" id="contact">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-6 col-12">
									<div className="abt_side">
										<div className="logo">
											{" "}
											<img src="assets/images/footer_logo.png" />
										</div>
										<ul>
											<li>
												<Link to="#">contact@postgoo.com</Link>
											</li>
											<li>
												<Link to="#">+212 (06)60-333340</Link>
											</li>
										</ul>
										<ul className="social_media">
											<li>
												<Link to="#">
													<i className="icofont-facebook"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-twitter"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-instagram"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-pinterest"></i>
												</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-12">
									<div className="links">
										<h3>Useful Links</h3>
										<ul>
											<li>
												<Link to="/">Home</Link>
											</li>
											<li>
												<Link to="/about">About us</Link>
											</li>
											<li>
												<Link to="#">Services</Link>
											</li>
											<li>
												<Link to="/blog">Blog</Link>
											</li>
											<li>
												<Link to="/contact">Contact us</Link>
											</li>
										</ul>
									</div>
								</div>

								<div className="col-lg-3 col-md-6 col-12">
									<div className="links">
										<h3>Help & Suport</h3>
										<ul>
											<li>
												<Link to="#">Support</Link>
											</li>
											<li>
												<Link to="/work">How it works</Link>
											</li>
											<li>
												<Link to="/tos">Terms & conditions</Link>
											</li>
											<li>
												<Link to="/privacy">Privacy policy</Link>
											</li>
										</ul>
									</div>
								</div>

								<div className="col-lg-2 col-md-6 col-12">
									<div className="try_out">
										<h3>Let’s Try Out</h3>
										<ul className="app_btn">
											<li>
												<Link to="#">
													<img src="assets/images/appstore_blue.png" />
												</Link>
											</li>
											<li>
												<Link to="#">
													<img src="assets/images/googleplay_blue.png" />
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="bottom_footer">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<p>© Copyrights 2024. All rights reserved.</p>
								</div>
								<div className="col-md-6">
									<p className="developer_text">
										Design & developed by{" "}
										<a href="https://postgoo.com" target="blank">
											Postgoo
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="go_top">
						<span>
							<img src="assets/images/go_top-one.png" />
						</span>
					</div>
				</footer>
			)}

			{footer.f5 && (
				<footer>
					<div className="top_footer footer-wave" id="contact">
						<div className="container">
							<span className="banner_shape1">
								{" "}
								<img src="assets/images/banner-shape1.png" />{" "}
							</span>
							<span className="banner_shape2">
								{" "}
								<img src="assets/images/banner-shape2.png" />{" "}
							</span>
							<div className="row">
								<div className="col-lg-4 col-md-6 col-12">
									<div className="abt_side">
										<div className="logo">
											{" "}
											<img src="assets/images/footer_logo.png" />
										</div>
										<ul>
											<li>
												<Link to="#">contact@postgoo.com</Link>
											</li>
											<li>
												<Link to="#">+212 (06)60-333340</Link>
											</li>
										</ul>
										<ul className="social_media">
											<li>
												<Link to="#">
													<i className="icofont-facebook"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-twitter"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-instagram"></i>
												</Link>
											</li>
											<li>
												<Link to="#">
													<i className="icofont-pinterest"></i>
												</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-12">
									<div className="links">
										<h3>Useful Links</h3>
										<ul>
											<li>
												<Link to="/">Home</Link>
											</li>
											<li>
												<Link to="/about">About us</Link>
											</li>
											<li>
												<Link to="#">Services</Link>
											</li>
											<li>
												<Link to="/blog">Blog</Link>
											</li>
											<li>
												<Link to="/contact">Contact us</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-12">
									<div className="links">
										<h3>Help & Suport</h3>
										<ul>
											<li>
												<Link to="#">Support</Link>
											</li>
											<li>
												<Link to="/work">How it works</Link>
											</li>
											<li>
												<Link to="/tos">Terms & conditions</Link>
											</li>
											<li>
												<Link to="/privacy">Privacy policy</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-2 col-md-6 col-12">
									<div className="try_out">
										<h3>Let’s Try Out</h3>
										<ul className="app_btn">
											<li>
												<Link to="#">
													<img src="assets/images/appstore_blue.png" />
												</Link>
											</li>
											<li>
												<Link to="#">
													<img src="assets/images/googleplay_blue.png" />
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="bottom_footer">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<p>© Copyrights 2024. All rights reserved.</p>
								</div>
								<div className="col-md-6">
									<p className="developer_text">
										Design & developed by{" "}
										<a href="https://postgoo.com" target="blank">
											Postgoo
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="go_top">
						<span>
							<img src="assets/images/go_top.png" />
						</span>
					</div>
				</footer>
			)}
		</>
	);
};

export default Main;
